@import url('https://fonts.googleapis.com/css2?family=Tajawal&display=swap');

* {
    font-family: 'Tajawal', sans-serif;
}

body {
    background-color: #24214a;
    height: 100%;
    margin: 0;
    overflow: hidden;
    
}
.app_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #24214a;
    height: 100vh;
}

.content_wrapper {
    display: flex;
    justify-content: center;
}