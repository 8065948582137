.header_wrapper {
    display: flex;
    height: 60px;
    width: 100vw;
    background-color: rgb(252, 251, 255);
    color: #24214a;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
}

.logo_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 20%;
    margin-left: 1em;
}

.title_wrapper {
    line-height: 10%;
    text-align: center;
    h1 {
        font-size: 2em;
        font-weight: bolder;
    }
    h2 {
        font-size: 1.2em;
        font-weight: bold;
    }
    
}
.menu_wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 20%;
    margin-left: 1em;
}

.nav {
    display: flex;
    justify-content: space-around;
    li {
        list-style: none;
        margin: 1em;
        cursor: pointer;
    }
}

.logo {
    height: 40px;
}