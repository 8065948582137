
.main_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
}

.svg_wrapper {
    display: block;
    margin: 0 auto 0;
}
.text_container {
    position: absolute;
    top: 4em;
    left: 2em;
    width: 30%;
    color: white;
    font-size: 1.5em;
    font-weight: 900;
    pointer-events: none;


}

.metadata_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: absolute;
    top: 6em;
    right: 2em;
    width: 30%;
    color: white;
    font-size: 1em;
    font-weight: 600;

    .metadata_label {
            padding: 0.25em 0.75em;
            margin: 0.2em;
            text-align: right;
    }

    .metadata_value {
        border-radius: 0.7em;
        padding: 0.25em 0.45em;
        margin: 0.2em;
        --highlight-color: #b4b1d6;
        border: 2px solid white;
        overflow: none;
        width: fit-content;
        background-color:var(--highlight-color);
        color: #24214a;

    }

    .selected {
        background-color: white;
    }
}



circle {
    cursor: pointer;
}

button {
    cursor: pointer;
}

.emoji {
    pointer-events: none;
}

.period_menu {
    display: flex;
    justify-content: space-around;
    width: 85%;

    .period_button {
        display: flex;
        border-radius: 0.7em;
        padding: 0.25em 0.45em;
        --highlight-color: #b4b1d6;
        border: 2px solid white;
        overflow: none;
        width: fit-content;
        background-color:var(--highlight-color);
        color: #24214a;

    }
    .selected {
        background-color: white;
    }
}

.legend_wrapper {
    display: flex;
    flex-direction: column;
    margin: 5em 0 auto;
    padding: 1.2em;
    color: white;
    align-items: center;
    background-color: #24214a;
    

    b {
        font-size: 1.2em;
        margin-bottom: 1.2em;
    }

    .legend_row {
        display: flex;
        justify-content: center;
    }

    .legend_cell {
        display: flex;
        flex-direction: column;
        margin: 0 1em;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .legend_label {
            color: white;
            text-align: center; 
            background-color: #24214a;      
        }

        
    }
}

.selected_legend {
    font-weight: bolder;
    font-size: 1.1em;
    color: rgb(171, 155, 190);
    
}