.modal_wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin: 0;
    height: 50%;
    width: 50%;
    left: 25%;
    top: 25%;
    background-color: white;
    border-radius: 0.5em;
    padding-bottom: 2em;

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        background: white;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #b4b1d6;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #8580bd;
    }
}

.close_modal_button {
    background-color: white;
    border: none; 
    padding: 0;
    margin: 1em; 
    align-self: flex-end;
    font-weight: bolder;
    color: #8580bd;
    cursor: pointer;
}

.modal_text {
    height: 100%;
    padding: 1em;
    position: relative;
    overflow-y: scroll;

    a, b {
        color:#8580bd;
    }
}

